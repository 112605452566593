import React from 'react';
import ContentWrapper from './ContentWrapper';
import PortfolioPageVideo from './PortfolioPageVideo';

const PortfolioPage = ({img, title, heroTitle, description, url, externalLink = false, children}) => {

  return <ContentWrapper>
        <h1 className="text-center mt-12 mb-12 text-3xl text-gray-800"></h1>
         <div className="mb-16 flex flex-wrap  justify-center ">
          {heroTitle && <div className="h-20 flex items-center portfolio-hero-title"><h2>{heroTitle}</h2></div>}
           <PortfolioPageVideo thumbnailImg={img} url={url} externalLink={externalLink} />
          
          <div className="w-full mt-14">
            <p className="mb-6 text-xl">{title}</p>
            <p className='md:w-2/3'>{description}</p>
          </div>
         {children}
        </div>
    </ContentWrapper>
}

export default PortfolioPage;
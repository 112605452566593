import React, { useState } from 'react';
import MainImage from '../../images/portfolio/PulverHeksa/thumbnail.png';

import PortfolioPage from '../../components/PortfolioPage';

const PortfolioCreaza = () => {
    
    const [showVideo, setShowVideo] = useState(false); 

    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }
    return <PortfolioPage 
        img={MainImage} 
        url="https://www.youtube.com/watch?v=XiBqVDDwZnA" 
        externalLink={true}
        title="Pulverheksas Magiske Jul" 
        description={
            <>
                <p>
                    Pulverheksas magiske jul er en animert film, basert på noen av de populære bøkene om Pulverheksa av Ingunn Aamodt. 
                    Filmen er for hele familien, og spesielt tilpasset det minste publikummet.
                </p>
                <br />
                <p>(2024)</p>
                <br />
                <p>
                    På dette prosjektet ble vi hyret inn av Verdiverkstedet for å gjøre karakteranimasjon. 
                    Vi jobbet med alle karakterene i serien, og sto for 20 minutter av karakteranimasjonen totalt. 
                    Programmet vi brukte var ToonBoom Harmony
                </p>
            </>
        }
    >
    </PortfolioPage>

}

export default PortfolioCreaza;
import React from "react";
import { useState } from "react";
import PlayIcon from "./Video/PlayIcon";
import VideoOverlay from "./Video/VideoOverlay";

interface Props {
  url: string;
  thumbnailImg: string;
  title?: string;
  externalLink?: boolean;
}

const PortfolioPageVideo = ({
  url,
  thumbnailImg,
  externalLink = false,
  title = "",
}: Props) => {
  const [videoUrl, setVideoUrl] = useState<null | string>(null);
  const onVideoSelect = () => {
    if (externalLink) {
      window.open(url);
    } else {
      setVideoUrl(url);
    }
  };

  return (
    <div className="w-full">
      {title && (
        <div className="h-12  flex items-center justify-center portfolio-hero-title">
          <h2 className="text-center md:text-xl">{title}</h2>
        </div>
      )}
      <div className="relative w-full" onClick={onVideoSelect}>
        <img src={thumbnailImg} className="backdrop-filter rounded-md w-full" />
        {url && (
          <div className="absolute rounded-md rounded-t opacity-0 inset-0 w-full h-full flex items-center justify-center transition-all cursor-pointer hover:bg-black hover:bg-opacity-50 hover:opacity-100 opacity-100 bg-black bg-opacity-50 md:opacity-0">
            <PlayIcon />
          </div>
        )}
      </div>
      <VideoOverlay isVisible={videoUrl} setVisible={setVideoUrl} />
    </div>
  );
};

export default PortfolioPageVideo;
